body{
  font-family: 'Open Sans', sans-serif;
  background: linear-gradient(90deg, rgba(240,238,238,1) 0%, rgba(255,255,255,1) 100%);
}

h1{
  color:#6C757D;
  font-family: 'Roboto', sans-serif;
}

h4{
  font-family: 'Roboto', sans-serif;
  text-align: center;
  color:#6C757D
}

.error{
  color:#DC3545;
}

div.sfondo{
  background: linear-gradient(90deg, rgba(240,238,238,1) 0%, rgba(255,255,255,1) 100%);
}

/*Navbar*/
.selected-page {
  background-color:  #fff;
  color: #007bff; 
}
.page{
  color: #fff;
}

@media screen and (max-width: 767px){
  .selected-page {
    background-color:  #007bff; 
    color:  #fff;
  }
  .page{
    color: #007bff; 
  }
}

.nav-items-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.nav-items-container .nav-link {
  flex: 1;
  text-align: center;
  padding: 5px;
  text-decoration: none;
  transition: background-color 0.3s;
}

#dropdown-basic::after {
  display: none !important;
}

.toggle-button:focus {
  box-shadow: none;
  border-color: #007bff;
  outline: none; /* Remove the default focus outline if desired */
}

/* Toolbar*/

.toolBar{
  margin-bottom: 5px;
}

.btnAdd{
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  font-size: medium;
  background: linear-gradient(90deg, rgba(0,123,255,0.9780287114845938) 24%, rgba(0,122,253,0.6334908963585435) 86%);
  color: white;
  border: none;
  cursor: pointer;
  border: 2px solid #007bff;
  border-radius: 25px;   
  box-shadow: 1px 1px rgb(189, 186, 186);
  margin-left: 20px;  
  padding: 10px 15px;
  
}

.btnAdd:hover{
  opacity: 0.8;
}

.btnAdd:active {
  background-color: #007bff;
  box-shadow: 0 5px #666;
  transform: translateY(4px);
}

.search-container {
  width: 100%;
 
}
.search-border-color {
  border-color: #007bff;
  color: #007bff;
}




input[type=text],input[type=date],input[type=email],input[type=password] {
  padding: 6px;
  font-size: 17px;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  color: #007bff;
  border: 1px solid #007bff;
  border-radius: 10px;
}

input[type=checkbox], input[type=radio]  {
  padding: 10px;
  font-size: 20px;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  color: #007bff;
  border: 1px solid #007bff;
  border-radius: 10px;
}

input:focus {
  outline: 2px solid rgb(37, 55, 219);  
}



/*Tabella*/

.tabella{
  padding: 10px;
 
}

thead{
  background-color: #007bff;
  color: #fff;
  text-align: center;
  

}



tbody tr{
  color:#6C757D;
  text-align: center;
  justify-content: center;
  background-color: #fff;
  box-shadow: #007bff 1px 1px ;
}




.noData{
  color:#007bff;
  text-align: center;
  
}
.statistiche, .voto{
  color:#007bff;
  background-color: #fff;
  border-radius: 25%;
  border: 1px solid;
  box-shadow: 2px 2px;
  height: 40px;
  width: 40px;
  cursor: pointer;
  display:inline-flex;
  justify-content: center;
  align-items: center;
}

.statistiche:hover,.voto:hover{
  opacity: 0.8;
  background: linear-gradient(90deg, rgba(0,123,255,0.9780287114845938) 24%, rgba(0,122,253,0.6334908963585435) 86%);
  color:  #fff;
}

.statistiche:active,.voto:active {
  box-shadow: 0 5px #666;
  transform: translateY(4px);
}
     

 .elimina {
  margin: 5px;
  color:#BD2130 ;
  background-color: #fff;
  width: 50%;
  border-radius: 25%;
  border: 1px solid;
  box-shadow: 2px 2px;
  height: 40px;
  width: 40px;
  cursor: pointer;
  display:inline-flex;
  justify-content: center;
  align-items: center;
}

.elimina:hover{
  opacity: 0.8;
  background: linear-gradient(90deg, rgba(189,33,48,1) 24%, rgba(189,33,48,0.6615021008403361) 86%);
  color:  #fff;
}

.elimina:active {
  box-shadow: 0 5px #666;
  transform: translateY(4px);
}
     


.aggiorna{
  margin-top: 5px;
  margin-bottom: 5px;
  margin-right: 5px;
  color: #28A745;
  background-color: #fff;
  width: 50%;
  border-radius: 25%;
  border: 1px solid;
  box-shadow: 2px 2px;
  height: 40px;
  width: 40px;
  cursor: pointer;
  display:inline-flex;
  justify-content: center;
  align-items: center;
  
}

.aggiorna:hover{
  opacity: 0.8;
  background: linear-gradient(90deg, rgba(40,167,69,1) 24%, rgba(40,167,69,0.7959558823529411) 86%);
  color:  #fff;
}

.aggiorna:active {
  box-shadow: 0 5px #666;
  transform: translateY(4px);
}

@media screen and (max-width: 380px){
  .statistiche,.aggiorna,.elimina,.voto{
    height: 40px;
    width: 40px;

  }
}

/*Form*/


.headerForm, .labelForm{
  color:#007bff;
}

input[type=radio] {
  
  border: 1px solid #007bff;
 
}

.selectForm{
  color:#007bff;
  border: 1px solid #007bff;
  border-radius: 20px;
}

p{color:#007bff;}

.labelCheckbox{
  color:#007bff;
}

.formAdd{
  background: linear-gradient(90deg, rgba(0,123,255,0.9780287114845938) 24%, rgba(0,122,253,0.6334908963585435) 86%);
}

.formAnnulla{
  background: linear-gradient(90deg, rgba(236,45,45,1) 3%, rgba(253,29,29,0.7819502801120448) 59%, rgba(226,2,2,0.5494572829131652) 90%);
}

.custom-label-color {
  color: #007bff;
}

/*Card*/

.card{
  border: 1px solid #007bff;
  border-radius: 20px;
  box-shadow: #007bff 2px 2px ;
  margin-bottom: 10px;
  width: 100%;
}

.cardText{
  color:#6C757D
}

.title{
  text-align: center;
  color:#007bff;
  font-weight: bold;
  margin: 10px;
}

.itemCard{
  color:#007bff;
  font-weight: bold;
  margin-left: 10px;
}

.btnGroupCard{
  float:right;
  margin-bottom: 5px;
}

.btnDomanda{
 text-align: center;
 padding-top: 10px;
}

.btnCard{
  background: linear-gradient(90deg, rgba(0,123,255,0.9780287114845938) 24%, rgba(0,122,253,0.6334908963585435) 86%);
  color: white;
  box-shadow: #007bff 2px 2px;
  margin: 5px;  
 
}

@media screen and (max-width: 414px){
  .btnCardText{
    font-size: 0;
  }
}


.btnNavPaziente{
  background: linear-gradient(90deg, rgba(0,123,255,0.9780287114845938) 24%, rgba(0,122,253,0.6334908963585435) 86%);
  color: white;
  box-shadow: #007bff 2px 2px;
  margin: 5px;  
 
}

.btnAssegna{
  background: linear-gradient(90deg, rgba(0,123,255,0.9780287114845938) 24%, rgba(0,122,253,0.6334908963585435) 86%);
  color: white;
  box-shadow: #007bff 2px 2px;
  margin: 5px;  
 width: 100%;
}

.selectFormGioco{
  color:#007bff;
  border: 1px solid #007bff;
  border-radius: 10px;
  width: 100%;
  text-align: center;
  display: inline;
  margin: 5px;
}

.selectFormAttivita{
  color:#007bff;
  border: 1px solid #007bff;
  border-radius: 10px;
  width: 100%;
  text-align: center;
  display: inline;
  margin: 5px;
}



@media screen and (max-width: 841px){
  .btnNavPaziente,.btnAssegna{
    height: 40px;
    width: 50px;
  }

  .btnText{
    font-size: 0;
  }



}

.btnCard:hover{
  opacity: 0.8;
}

.btnCard:active {
  box-shadow: 0 5px #666;
  transform: translateY(4px);
}


.linkMenu{
  display: flex;
  color:#007bff;
  padding: 10px 15px;
  gap: 15px;
  transition: all 0.5s;
  text-decoration: none;
}




/*Profilo*/

.profilo{
  padding: 20px;
}

.avatar{
  text-align: center;
}

.imgAvatar{
  height:40%;
  width: 40%;
}

.aggProfilo{
  float: right;
}

.datiProfilo{
  text-align: center;
  color:#6C757D;
}

.span{
  color: blue;
  font-weight: bold;
}




.opzioniDialoghi{
  color:#007bff;
}


.infoPaziente{
  color:#007bff;
  font-weight: bold;
}

.datiPaziente{
  color:#6C757D;
}

.tepTitle{
  color:#007bff;

}

.delCard{
  float: right;
}

.imgDialogo{
  height: fit-content;
  width: 100%;
}

.rispMini{
  text-align: center;
  color: #0f2666;
}

.parola{
  text-align: center;
  color: #0f2666;
  font-size: 30px;
 
}

.parolePaziente{

  text-align: center;
  color: #0f2666;
}

.inputLettera{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.inputLettera input{
  height: 57px;
  width: 56px;
  margin: 4px;
  text-transform: uppercase;
  
}

.parolaCategorizzazione{
  border: 2px solid #007bff;
  border-radius: 20px;
  text-align: center;
  color: #0f2666;
}

.btn-space {
  float: left;
 }
.btn-space1 {
 float: right;
}

.btn-space:active {
  transform: translateY(4px);
}

.btn-space1:active {
  transform: translateY(4px);
}


.inputForm{
  margin: 10px;
}

.inputPazienteView{
  background: linear-gradient(90deg, rgba(0,123,255,0.9780287114845938) 24%, rgba(0,122,253,0.6334908963585435) 86%);
  color: white;
  box-shadow: #007bff 2px 2px;
  margin: 5px;  
}

.inputPazienteView:active {
  box-shadow: 0 5px #007bff;
  transform: translateY(4px);
}




.titleStat{
  text-align: center;
  color:#0f2666;
  margin: 10px;
}

.listMargin{
  margin:5px
}

.NavBarText{
  text-align: center;
  color:#007bff;
  font-size: xx-large;
  margin: 5px;
}


.audioPlayer{
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
 padding:20px;
}

.cardAttivita{
  width: 100%;
  height: 100%;
}

.formGame{
  width: 100%; 
text-align: center;
}


.TabHeader{
  margin-top: 10px; 
}

@media screen and (max-width: 700px) {
  .TabHeader{
      margin-top: 10px;  
  }
}

.cardTitle{
  color:#6C757D;
}

.feedback-message {
  color: green; /* or any color you prefer */
  margin-top: 10px;
}

.switch-file{
  margin-left: 20px;
}
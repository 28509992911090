/*Header */
.Header{
    top: 10px;
    z-index: 1000;
    
  }
  @media screen and (max-width: 700px) {
  
    .Header{
        margin-top: 33px;
        top: 33px; 
    }
 
  
  }
  
 
  
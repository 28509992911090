
.responsiveTable {
    width: 100%;
    padding: 10px;
  }
  
  .responsiveTable td .tdBefore {
    display: none;
  }

  .responsiveTable tr:hover td, .responsiveTable tbody tr:hover th {
    background-color: #dee4f5;
    color:#007bff;
  }
  
  @media screen and (max-width: 1000px) {

  
    .responsiveTable table,
    .responsiveTable thead,
    .responsiveTable tbody,
    .responsiveTable th,
    .responsiveTable td,
    .responsiveTable tr {
      display: block;
    }
  
    .responsiveTable thead tr {
      position: absolute;
      top: -9999px;
      left: -9999px;
      border-bottom: 2px solid #333;
      
    }
  
    .responsiveTable tbody tr {
      border: 1px solid #007bff;
      padding: .25em;
    }
  
    .responsiveTable td.pivoted {
      border: none !important;
      position: relative;
      padding-left: calc(50% + 10px) !important;
      text-align: left !important;
      white-space: pre-wrap;
      overflow-wrap: break-word;
    }
  
    .responsiveTable td .tdBefore {
    
      position: absolute;
      display: block;
      color:#007bff;
      left: 1rem;
      width: calc(55% - 20px);
      white-space: pre-wrap;
      overflow-wrap: break-word;
      text-align: left !important;
      font-weight: 600;
      
    }

    .arrow{
      display: none;
    }
  }
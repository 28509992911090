main {
  padding: 0;
}



.header{
  position: sticky;
  top:0px;
  padding:10px;
  background: linear-gradient(90deg, rgba(0,123,255,1) 24%, rgba(0,122,253,1) 86%);
  color: white;
  border: 1px solid #007bff;
  z-index: 1;
  height:70px;
  }

  .btnExit{
    float: left;
    width: 100px;
    border-radius: 25px;
    border: 1px solid #007bff;
    color: #495057;
    background: #fff;
  }

  .btnExit:hover{
    background:  white;
    color:  #007bff;
  
  }

  .btnExit:active {
    background-color: white;
    box-shadow: 0 3px #666;
    transform: translateY(4px);
  }

  h4{
        color:white;
        text-align: center;
       
       
    }

.listaEsercizi{
    margin-top: 10px;
    margin-right: 16px;
    margin-left: 16px;
    padding-top: 60px;
    border:none;
    border-radius: 25px;
    
}

.imgEsercizio{
    
    cursor: pointer;
    width: 100%;
    height: 20vw;
    object-fit: cover;
}

@media screen and (max-width: 900px) {
  .imgEsercizio{
    
    width: 100%;
    height: 40vw;
    object-fit: cover;
}


}

.imgEsercizio:active{
    box-shadow: 0 3px #666;
    transform: translateY(4px);
}



.titoloDomanda{
  color:#007bff;
  font-size: xx-large;
  text-align: center;
  margin-bottom: 40px;
}


.quiz-button{
  display: grid;
  grid-template-columns:auto auto ; 
  font-size: x-large;
  gap: 30px;
 
}

.btnRispErrata,.btnRispCorretta{
  border: 1px solid ;
  background: linear-gradient(90deg, rgba(0,123,255,0.9780287114845938) 24%, rgba(0,122,253,0.6334908963585435) 86%);
    color: white;
    width:auto;
    height: 50px;
    
    box-shadow: #007bff 2px 2px;
    margin: 5px;  
   
}

.btnRispCorretta:active{
  background: linear-gradient(90deg, rgba(92,226,92,1) 47%, rgba(255,255,255,1) 100%);
  box-shadow: 0 3px #666;
  transform: translateY(4px);
}


.btnRispErrata:active{
  background: linear-gradient(90deg, rgba(236,45,45,1) 3%, rgba(253,29,29,0.7819502801120448) 59%, rgba(226,2,2,0.5494572829131652) 90%);
  box-shadow: 0 3px #666;
  transform: translateY(4px);
}

.cardNext{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  width: auto;
}

.btnNext{
  margin: 50px;
  width: 300px;
  border-radius: 25px;
  border: 1px solid ;
  color: #495057;
  background: #fff;
  font-size: large;
  
  
}

.btnNext:hover{
  background:  white;
  color:  #007bff;

}

.btnNext:active {
  background-color: white;
  box-shadow: 0 3px #495057;
  transform: translateY(4px);
}

.cardGioco{
  left: 25%;
  width: 50%;
}

@media screen and (max-width: 671px) {
  .cardGioco{
    margin-bottom: 10px;
    left: 10%;
   
  width: 80%;
  }
}

.cardAvviso{
  left: 25%;
  margin-top:40px;
  width: 50%;
}

@media screen and (max-width: 671px) {
  .cardAvviso{
    margin-bottom: 10px;
  left:auto;
  width: 100%;
  }
}
.avviso{
  text-align: center;
  color: #007bff;
}

.score{
  text-align: center;
  color: #0155af;
}

.rispEsatte{
  text-align: center;
  color: rgb(22, 196, 22);
  font:bold;
}

.rispErrate{
  color:rgba(204, 45, 45, 0.897);
  font:bold;
  text-align: center;
}

.activeColumn {
  background-color: #085fbd; 
}


.Next{
  border-radius: 25px;
  border: 1px solid ;
  color: #495057;
  background: #fff;
}

.Next:hover{
  background:  white;
  color:  #007bff;

}

.Next:active {
  background-color: white;
  box-shadow: 0 3px #495057;
  transform: translateY(4px);
}

.centered-question {
  text-align: center;
}

.centered-answer {
  text-align: center;
  margin-top: 3px; /* Adjust the margin as needed */
}

.white-icon {

  color: white !important;

}

.risposta{
  font-size: 50px;
}


.App {
   font-family: 'Roboto', sans-serif;
    display: flex;
    min-height: 100vh;
    align-items: center;
    justify-content: center;
    color:#6C757D;
    background: linear-gradient(90deg, rgb(112, 105, 240) 0%, rgba(49,100,244,1) 35%, rgba(0,212,255,1) 100%);
  }
  

  .auth-form-container, .login-form, .register-form {
    display: flex;
    flex-direction: column;
    text-align: center;
    
  }

  
  
  .formFeedback{
    min-width: 5px;
    text-align: center;
  }

  .avviso{
    margin-top: 10px;
    
  }
  .textAvviso{
    color:#0f2666;
  }

  .forgotPassword{
    color:#cc1818;
  }
  
  @media screen and (min-width:200px) {
    .auth-form-container {
      padding: 5rem;
      border: 2px solid #007bff;
      background-color: #fefefe;
      border-radius: 10px;
      margin: 0.5rem;
      box-shadow: #007bff 1px 1px ;
    }
  }
  

    
  @media screen and (max-width:500px) {
    .App{
     background-image: none;
     background: linear-gradient(90deg, rgb(112, 105, 240) 0%, rgba(49,100,244,1) 35%, rgba(0,212,255,1) 100%);
     width: 100%;
    }

    .auth-form-container {
      padding: 2rem;
      border: 2px solid #007bff;
      background-color: #fefefe;
      border-radius: 10px;
      margin: 0.5rem;
    }
   
  }
  
  label {
    text-align: left;
    padding: 0.25rem 0;
  }
  
  input {
    margin: 0.5rem 0;
    padding: 1rem;
    border: 1px solid #007bff;
    border-radius: 25px;  
  }
  input:focus {
    outline: 2px solid rgb(37, 55, 219);  
  }
  
  .login-btn, .register-btn{
    padding: 10px 15px;
    border: 1px solid #007bff;
    border-radius: 15px;    
    cursor: pointer;
    background: linear-gradient(90deg, rgba(0,123,255,0.9780287114845938) 24%, rgba(0,122,253,0.6334908963585435) 86%);
    color: white;
    box-shadow: #3164F4 2px 2px;
  }

  .login-btn:active,.register-btn:active {
    box-shadow: 0 5px #666;
    transform: translateY(4px);
  }
       

  .login-btn:hover{
    opacity: 0.8;
  }   

  .register-btn:hover{
    opacity: 0.8;
  }   
  .link-btn {
    background: none;
    color:  #007bff;
    padding: 20px;
    border:none;
    text-decoration: underline;
    cursor: pointer;
  }

  .link-btn:hover{
    opacity: 0.8;
  }